$(function () {
  /**
   * Callback form
   */
  GLightbox({
    selector: `#showCallbackForm`,
    width: `300px`,
    height: `auto`,
    content: document.getElementById(`callbackFormModal`),
  })

  $(`#sendCallbackMessage`).on(`click`, sendCallbackForm)

  /**
   * Событие смены языка
   * @param {Object} event
   */
  $(`#form-language .language-select`).on(`click`, function (event) {
    event.preventDefault()

    $(`#form-language input[name='code']`).val($(this).attr(`name`))

    $(`#form-language`).trigger(`submit`)
  })

  /**
   * Поиск
   */
  $(`#search input`).on(`focus`, addClassSearch).on(`blur`, removeClassSearch)

  /**
   * Изменение цвета товара
   */
  $(`#content, .change-color`).on(`click`, `[data-action="setColor"]`, setColor)

  /**
   * Поиск
   */
  $(`#search input[name='search']`)
    .parent()
    .find(`button`)
    .on(`click`, function () {
      let url = `${$(`base`).attr(`href`)}index.php?route=product/search`

      const value = $(`#search input[name='search']`).val()

      if (value) {
        url += `&search=${encodeURIComponent(value)}`
      }

      location = url
    })

  $(`#search input[name='search']`).on(`keydown`, function (e) {
    if (e.keyCode == 13) {
      $(`#search input[name='search']`).parent().find(`button`).trigger(`click`)
    }
  })

  /**
   * Живой поиск
   */
  $(`#search input[name='search']`).autocomplete({
    source: function (request, response) {
      if (request.length > 1) {
        $.ajax({
          url: `index.php?route=product/search_autocomplete&filter_name=${encodeURIComponent(
            request
          )}`,
          dataType: `json`,
          success: function (json) {
            response(
              $.map(json.products, function (item) {
                let productblock
                if (item[`product_special`]) {
                  productblock = `<span class="product-search d-flex"><img src="${
                    item[`product_image`]
                  }" class="mr-3" alt="${
                    item[`product_name`]
                  }"><span class="flex-fill">${
                    item[`product_name`]
                  }<br><small><span class="price-old">${
                    item[`product_price`]
                  }</span>${item[`product_special`]}</small></span></span>`
                } else {
                  productblock = `<span class="product-search d-flex"><img src="${
                    item[`product_image`]
                  }" class="mr-3" alt="${
                    item[`product_name`]
                  }"><span class="flex-fill">${
                    item[`product_name`]
                  }<br><small>${item[`product_price`]}</small></span></span>`
                }
                
                return {
                  label: productblock,
                  search_name: item[`product_name`],
                  value: encodeURIComponent(item[`product_name`]),
                  path: item[`path`],
                }
              })
            )
          },
        })
      }
    },
    select: function (item) {
      var product_name = $(`<textarea />`).html(item[`search_name`]).text()
      $(`#search input[name='search']`).val(product_name)

      // $(`#search input[name='search']`).parent().find(`button`).trigger(`click`)
      if (item[`path`]) {
        location.href = item[`path`].replace(/\&amp;/g,`&`)
      } else {
        $(`#search input[name='search']`).parent().find(`button`).trigger(`click`)
      }
    },
  })

  // Show nav
  $(`#buttonShowSidebar`).on(`click`, openNavigation)
  $(`.sidebar-overlay`).on(`click`, closeNavigation)

  // To top button
	const toTop = $(`#toTop`)

	$(window).on(`scroll`, function() {
		const $this = $(this)
		if ($this.scrollTop() > 200) {
			toTop.addClass(`show`)
		} else {
			toTop.removeClass(`show`)
		}
	})

	$(window).trigger(`scroll`)

	toTop.on(`click`, function() {
		$(`html, body`).animate({scrollTop: 0}, 500)
		return false
	})
})

// Show nav
const openNavigation = () => {
  const bodyWidthInit = $(`body`).outerWidth()
  $(`body`)
    .addClass(`sidebar-open`)
    .css(`paddingRight`, _setScrollBarSpace(bodyWidthInit))
  $(`#sidebarLeft`).addClass(`open`)
}

const closeNavigation = () => {
  $(`body`).removeClass(`sidebar-open`).css(`paddingRight`, ``)
  $(`#sidebarLeft`).removeClass(`open`)
}

const _setScrollBarSpace = (bodyWidthInit) =>
  $(`body`).outerWidth() - bodyWidthInit

// Cart add remove functions
const cart = {
  add: function (product_id, quantity) {
    $.ajax({
      url: `index.php?route=checkout/cart/add`,
      type: `post`,
      data: `product_id=${product_id}&quantity=${
        typeof quantity != `undefined` ? quantity : 1
      }`,
      dataType: `json`,
      success: function (json) {
        $(`.alert, .text-danger`).remove()

        if (json[`redirect`]) {
          location = json[`redirect`]
        }

        if (json[`success`]) {
          toastr.success(json[`success`])

          // Need to set timeout otherwise it wont update the total
          setTimeout(function () {
            $(`#cart #cart-total`).html(json[`total`])
          }, 100)

          $(`#cart > ul`).load(`index.php?route=common/cart/info ul li`)
        }
      },
      error: function (xhr) {
        console.log(xhr.responseText)
      },
    })
  },
  update: function (key, quantity) {
    $.ajax({
      url: `index.php?route=checkout/cart/edit`,
      type: `post`,
      data: `key=${key}&quantity=${
        typeof quantity != `undefined` ? quantity : 1
      }`,
      dataType: `json`,
      success: function (json) {
        // Need to set timeout otherwise it wont update the total
        setTimeout(function () {
          $(`#cart > button`).html(
            `<span id="cart-total"><i class="fa fa-shopping-cart"></i> ${json.total}</span>`
          )
        }, 100)

        if (
          location.pathname == `/cart/` ||
          location.pathname == `/checkout/`
        ) {
          location = `index.php?route=checkout/cart`
        } else {
          $(`#cart > ul`).load(`index.php?route=common/cart/info ul li`)
        }
      },
      error: function (xhr) {
        console.log(xhr.responseText)
      },
    })
  },
  remove: function (key) {
    $.ajax({
      url: `index.php?route=checkout/cart/remove`,
      type: `post`,
      data: `key=${key}`,
      dataType: `json`,
      success: function (json) {
        // Need to set timeout otherwise it wont update the total
        setTimeout(function () {
          $(`#cart > button`).html(
            `<span id="cart-total"><i class="fa fa-shopping-cart"></i> ${json.total}</span>`
          )
        }, 100)

        if (
          location.pathname == `/cart/` ||
          location.pathname == `/checkout/` ||
          location.pathname == `/ru/cart/` ||
          location.pathname == `/ru/checkout/`
        ) {
          location = `index.php?route=checkout/cart`
        } else {
          $(`#cart > ul`).load(`index.php?route=common/cart/info ul li`)
        }
      },
      error: function (xhr) {
        console.log(xhr.responseText)
      },
    })
  },
}

function sendCallbackForm() {
  $.ajax({
    url: `index.php?route=information/contact/send&comment_free=true`,
    method: `post`,
    data: $(`#callbackForm`).serialize(),
    dataType: `json`,
    success(data) {
      $(`.required`).removeClass(`has-error`)
      $(`form .text-danger`).remove()

      if (data.error) {
        for (let i = 0; i < Object.keys(data.error).length; i++) {
          $(`#input-${Object.keys(data.error)[i]}`)
            .parents(`.required`)
            .addClass(`has-error`)
            .append(
              `<div class="text-danger">${
                data.error[Object.keys(data.error)[i]]
              }</div>`
            )
        }

        toastr.error(data.error.message)
      }

      if (data.success) {
        toastr.success(data.success)
        $(`#callbackForm input[type='text']`).val(``)
        $(`#callbackForm textarea`).val(``)
        
        setTimeout(function() {
          $(`body`).find(`.glightbox-container .gclose`).trigger(`click`)
        }, 300)
      }
    },
    error(err) {
      console.log(err.responseText)
    },
  })
}

function addClassSearch() {
  $(`#search`).parents(`.block-search`).addClass(`active`)
}

function removeClassSearch() {
  $(`#search`).parents(`.block-search`).removeClass(`active`)
}

function setColor() {
  const $this = $(this)
  const container = $this.parents(`.product-layout`)

  const isSpecial = container.hasClass(`product-special`)

  // $.post(`index.php?route=product/product/get_card`, {
  //   product_id: $this.data(`id`),
  //   special: isSpecial ? 1 : 0,
  // }).done(function (data) {
  //   container.html(data).css(`opacity`, 1)
  // })

  if (!$this.hasClass(`active`)) {
    container.css(`opacity`, 0.5)
    
    $.get(`index.php?route=product/product/get_color_data&product_id=${$this.data(`id`)}`, function (data) {
      // container.html(data).css(`oproduct-colopacity`, 1)
      if (data) {
        container.find(`.product-color`).removeClass(`active`)
        $this.addClass(`active`)
        container.find(`a img`).attr(`src`, data.image).closest(`a`).attr(`href`, data.href)
        container.find(`.card-title`).html(`<a href="${data.href}">${data.name}</a>`)
        container.find(`.card-model`).text(data.model)
        if (data.special) {
          container.find(`a img`).before(`<span class="special">${data.text_special}</span>`)
          container.find(`.price`).html(`<div class="price-old">${data.price}</div><div class="price-new">${data.special}</div>`)
        } else {
          container.find(`.special`).remove()
          container.find(`.price`).html(`<div class="price-main">${data.price}</div>`)
        }
        container.find(`.stock-status`).removeClass(`text-success text-danger`).addClass(data.class).text(data.quantity_text)
        
        container.find(`.hidden-block`).remove()
        if (data.is_avaliable) {
          container.find(`.card`).append(`<div class="hidden-block text-center"><button type="button" class="btn btn-primary px-4" onclick="cart.add('${data.product_id}');">${data.button_cart}</button></div>`)
        }
        
      }
      container.css(`opacity`, 1)
    })
  }
}

// Autocomplete
;(function ($) {
  $.fn.autocomplete = function (option) {
    return this.each(function () {
      this.timer = null
      this.items = new Array()

      $.extend(this, option)

      $(this).attr(`autocomplete`, `off`)

      // Focus
      $(this).on(`focus`, function () {
        this.request()
      })

      // Blur
      $(this).on(`blur`, function () {
        setTimeout(
          function (object) {
            object.hide()
          },
          200,
          this
        )
      })

      // Keydown
      $(this).on(`keydown`, function (event) {
        switch (event.keyCode) {
          case 27: // escape
            this.hide()
            break
          default:
            this.request()
            break
        }
      })

      // Click
      this.click = function (event) {
        event.preventDefault()

        const value = $(event.target).closest(`li`).attr(`data-value`)

        if (value && this.items[value]) {
          this.select(this.items[value])
        }
      }

      // Show
      this.show = function () {
        var pos = $(this).position()

        $(this)
          .siblings(`ul.dropdown-menu`)
          .css({
            top: pos.top + $(this).outerHeight(),
            left: pos.left,
          })

        $(this).siblings(`ul.dropdown-menu`).show()
      }

      // Hide
      this.hide = function () {
        $(this).siblings(`ul.dropdown-menu`).hide()
      }

      // Request
      this.request = function () {
        clearTimeout(this.timer)

        this.timer = setTimeout(
          function (object) {
            object.source($(object).val(), $.proxy(object.response, object))
          },
          200,
          this
        )
      }

      // Response
      this.response = function (json) {
        let html = ``

        if (json.length) {
          for (let i = 0; i < json.length; i++) {
            this.items[json[i][`value`]] = json[i]
          }

          for (let i = 0; i < json.length; i++) {
            if (!json[i][`category`]) {
              html += `<li data-value="${json[i][`value`]}"><a href="#">${
                json[i][`label`]
              }</a></li>`
            }
          }

          // Get all the ones with a categories
          var category = new Array()

          for (let i = 0; i < json.length; i++) {
            if (json[i][`category`]) {
              if (!category[json[i][`category`]]) {
                category[json[i][`category`]] = new Array()
                category[json[i][`category`]][`name`] = json[i][`category`]
                category[json[i][`category`]][`item`] = new Array()
              }

              category[json[i][`category`]][`item`].push(json[i])
            }
          }

          for (let i in category) {
            html += `<li class="dropdown-header">${category[i][`name`]}</li>`

            for (let j = 0; j < category[i][`item`].length; j++) {
              html += `<li data-value="${
                category[i][`item`][j][`value`]
              }"><a href="#">&nbsp;&nbsp;&nbsp;${
                category[i][`item`][j][`label`]
              }</a></li>`
            }
          }
        }

        if (html) {
          this.show()
        } else {
          this.hide()
        }

        $(this).siblings(`ul.dropdown-menu`).html(html)
      }

      $(this).after(`<ul class="dropdown-menu" style="display: none;"></ul>`)
      $(this)
        .siblings(`ul.dropdown-menu`)
        .delegate(`a`, `click`, $.proxy(this.click, this))
    })
  }
})(window.jQuery)
